import { Method } from "axios";

const methods = [
  "get",
  "GET",
  "post",
  "POST",
  "put",
  "PUT",
  "patch",
  "PATCH",
  "delete",
  "DELETE",
  "head",
  "HEAD",
  "options",
  "OPTIONS",
  "purge",
  "PURGE",
  "link",
  "LINK",
  "unlink",
  "UNLINK",
];
export function isMethod(value?: string): value is Method {
  if (!value) {
    return false;
  }
  return methods.includes(value);
}
