import { createContext, useContext } from "react";

export type PendingDataContextValues = {
  isPending: boolean;
  updatePendingState: (queryId: string, isPending: boolean) => void;
};

export const PendingDataContext =
  createContext<PendingDataContextValues | null>(null);

export function usePendingData(): PendingDataContextValues {
  const context = useContext(PendingDataContext);
  if (context === null) {
    throw new Error(
      "usePendingData must only be used within a PendingDataProvider"
    );
  }
  return context;
}
