import { memo, useMemo, JSX } from "react";

import { useSearchParams } from "../../../../../packages/react-router/src";
import { Offer } from "../../types/offers";

import Missions from "./Missions";

export type MissionsBaseProps = {
  offer: Offer;
};

function MissionsBase({ offer }: MissionsBaseProps): JSX.Element {
  const [searchParams] = useSearchParams();

  const isFallback = useMemo(
    () => searchParams.has("fallback"),
    [searchParams]
  );

  return <Missions offer={offer} isFallback={isFallback} />;
}

export default memo(MissionsBase);
