import { memo, JSX, useMemo, useCallback } from "react";

import { useSearchParams } from "@mobsuccess-devops/react-router";

import { isDesktop } from "react-device-detect";
import styled from "styled-components";

import { ReactComponent as GoldCoinIcon } from "../../../assets/icons/custom/gold-coin.svg";
import { useSelectedOfferAtom } from "../../../features/atoms/offers";
import { Offer } from "../../../types/offers";
import { TagList } from "../../TagList/TagList";
import TextMD from "../../UI/TextMD";
import TextXS from "../../UI/TextXS";

type OfferCardUIProps = {
  $isSurvey: boolean;
};

const OfferCardUI = styled.div<OfferCardUIProps>`
  display: flex;
  width: 100%;
  height: 110px;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  ${({ $isSurvey, theme }) =>
    $isSurvey &&
    `
    border: 2px solid ${theme.palette.secondary[500]};
  `}
`;

const LogoContainerUI = styled.div<{ $logo: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 6px;
  padding-left: 16px;
  & > span {
    width: 44px;
    height: 44px;
    background-image: ${({ $logo }) => `url(${$logo})`};
    background-size: cover;
    border-radius: 8px;
  }
`;

const DetailsContainerUI = styled.div`
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  padding-left: 6px;
`;

const BadgesContainerUI = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  & > * {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
`;

BadgesContainerUI.displayName = "BadgesContainerUI";

const DescriptionContainerUI = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin-top: 8px;
`;

const PointsContainerUI = styled.div`
  display: flex;
  width: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  padding-left: 4px;
  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const OfferNameUI = styled(TextMD)`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.neutral[900]};
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OfferDescriptionUI = styled(TextXS)`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.palette.neutral[500]};
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
`;

const OfferPointsUI = styled(TextXS)`
  color: ${({ theme }) => theme.palette.neutral[500]};
  text-align: center;
`;

const MoneyIconContainerUI = styled.img`
  width: 24px;
`;

type LargeOfferProps = {
  offer: Offer;
};

function LargeOffer({ offer }: LargeOfferProps): JSX.Element {
  const [, setSelectedOffer] = useSelectedOfferAtom();
  const [searchParams] = useSearchParams();

  const isFallback = useMemo(
    () => searchParams.has("fallback"),
    [searchParams]
  );

  const handleClick = useCallback(() => {
    setSelectedOffer(offer);
  }, [offer, setSelectedOffer]);

  return (
    <OfferCardUI $isSurvey={offer.hasSurveyTag} onClick={handleClick}>
      <LogoContainerUI $logo={offer.icone}>
        <span />
      </LogoContainerUI>
      <DetailsContainerUI>
        {offer.hasSurveyTag ? (
          <OfferNameUI>New Survey!</OfferNameUI>
        ) : (
          <OfferNameUI>
            {offer.name}
            {isDesktop && offer?.os === "ios" && " - IOS"}
            {isDesktop && offer?.os === "android" && " - Android"}
          </OfferNameUI>
        )}
        <BadgesContainerUI>
          <TagList offer={offer} />
        </BadgesContainerUI>
        <DescriptionContainerUI>
          <OfferDescriptionUI>{offer?.small_description}</OfferDescriptionUI>
        </DescriptionContainerUI>
      </DetailsContainerUI>
      {!offer.hasDiscoveryTag && !isFallback && !offer.hasSurveyTag && (
        <PointsContainerUI>
          {offer.moneyIcon ? (
            <MoneyIconContainerUI src={offer.moneyIcon} />
          ) : (
            <GoldCoinIcon />
          )}
          <OfferPointsUI>
            {offer.rewardAmount} {offer.moneyName}
          </OfferPointsUI>
        </PointsContainerUI>
      )}
    </OfferCardUI>
  );
}

const MemoizedLargeOffer = memo(LargeOffer);
export { MemoizedLargeOffer as LargeOffer };
