import { useMemo } from "react";

import { RelativeRoutingType } from "@remix-run/router";

import { useHref as useInternalHref } from "react-router-dom";

import { OverloadTo } from "../../types/OverloadTo";

import useRenderUrlWithKeepParams from "./useRenderUrlWithKeepParams";

export function useHref(
  to: OverloadTo,
  options?: { relative?: RelativeRoutingType }
): string {
  const renderUrlWithKeepParams = useRenderUrlWithKeepParams();

  const href = useMemo(() => {
    return renderUrlWithKeepParams(to, options).toString();
  }, [options, renderUrlWithKeepParams, to]);

  return useInternalHref(href);
}
