import { useCallback } from "react";

import {
  NavigateOptions,
  useNavigate as useInternalNavigate,
} from "react-router-dom";

import { OverloadTo } from "../../types/OverloadTo";
import { useListeningRef } from "../references";
import { useRouterContext } from "../router";

import useRenderUrlWithKeepParams from "./useRenderUrlWithKeepParams";

export type NavigateFunction = {
  (to: OverloadTo, options?: NavigateOptions): void;
  (delta: number, options?: never): void;
};

function useNavigate(): NavigateFunction {
  const navigate = useInternalNavigate();
  const { dispatchNavigationEvent } = useRouterContext();

  const navigateRef = useListeningRef(navigate);
  const renderUrlWithKeepParams = useRenderUrlWithKeepParams();

  return useCallback<NavigateFunction>(
    (to, options): void => {
      const navigate = navigateRef.current;
      if (typeof to === "number") {
        navigate(to);
        return dispatchNavigationEvent();
      }
      const resolvedTo = renderUrlWithKeepParams(to, options);
      navigate(resolvedTo, options);
      dispatchNavigationEvent();
    },
    [dispatchNavigationEvent, navigateRef, renderUrlWithKeepParams]
  );
}

export default useNavigate;
