import axios from "axios";

import { concurrency } from "./concurrency";
import { gatewayTimeout } from "./gateway-timeout";
import { redirect } from "./request-redirect";
import { retry } from "./request-retry";

// concurrency
axios.interceptors.request.use(concurrency.request.bind(axios));
axios.interceptors.response.use(
  concurrency.response.bind(axios),
  concurrency.error.bind(axios)
);

// gateway-timeout
axios.interceptors.response.use(undefined, gatewayTimeout.error.bind(axios));

// request-redirect
axios.interceptors.response.use(redirect.response.bind(axios));

// request-retry
axios.interceptors.request.use(retry.request.bind(axios));
axios.interceptors.response.use(
  retry.response.bind(axios),
  retry.error.bind(axios)
);
