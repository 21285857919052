import { ReactNode, useMemo, JSX } from "react";

import { ThemeProvider } from "styled-components";
import styled from "styled-components";

import theme, { palettes } from "../../theme/theme";

const RootUI = styled.div`
  svg {
    overflow: initial;
  }
`;

RootUI.displayName = "RootUI";

type AppMarketingThemeProviderProps = {
  children: ReactNode;
};

function AppMarketingThemeProvider({
  children,
}: AppMarketingThemeProviderProps): JSX.Element {
  const themePalette = useMemo(
    () => ({
      ...theme,
      palette: palettes["appMarketing"],
    }),
    []
  );

  return (
    <ThemeProvider theme={themePalette}>
      <RootUI>
        <div id="portal"></div>
        {children}
      </RootUI>
    </ThemeProvider>
  );
}

export default AppMarketingThemeProvider;
