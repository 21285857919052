import { JSX, memo } from "react";

import styled from "styled-components";

import { Offer } from "../../types/offers";
import TextLG from "../UI/TextLG";
import TextSM from "../UI/TextSM";

import { LargeOffer } from "./LargeOffer/LargeOffer";

const LargeOfferListUI = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;
`;

const TextSMUI = styled(TextSM)`
  color: ${({ theme }) => theme.palette.neutral[500]};
`;

type LargeOfferListProps = {
  title: string;
  offers: Offer[];
};

function LargeOfferList({ title, offers }: LargeOfferListProps): JSX.Element {
  return (
    <LargeOfferListUI>
      <TextLG>{title}</TextLG>
      {offers.length ? (
        offers.map((offer) => <LargeOffer offer={offer} key={offer.id} />)
      ) : (
        <TextSMUI>No offers yet.</TextSMUI>
      )}
    </LargeOfferListUI>
  );
}

const MemoizedLargeOfferList = memo(LargeOfferList);
export { MemoizedLargeOfferList as LargeOfferList };
