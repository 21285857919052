import { Method } from "axios";

import type { Config, QueryArgs } from "../../types/context";
import { env } from "../utils/env";

import { isMethod } from "./method";

const defaultBranch = env.defaultBranch ?? "master";

type Params = {
  [key: string]: string | number | boolean;
};

type Host = string;

const re = /(?:\{|%7B)(?<param>[a-z0-9A-Z_]+)(?:\}|%7D)/g;

function getLegacyUrl({
  host,
  params,
}: {
  host: Host;
  params: Params;
}): string {
  return `${host}/webservices/rest/get-query${
    params
      ? `?${Object.entries(params)
          .map(
            ([name, value]) =>
              `${encodeURIComponent(name)}=${encodeURIComponent(
                Array.isArray(value) ? JSON.stringify(value) : value
              )}`
          )
          .join("&")}`
      : ""
  }`;
}

function createURL(pathname: string, base: string): URL {
  const baseUrl = new URL(base, window.location.origin);
  const fullPathname = `${baseUrl.pathname}/${pathname}`
    .replace(/(\/)+/g, "/")
    .replace(/\/$/, "");
  return new URL(fullPathname, baseUrl);
}

export function parseQueryName(queryName: string): {
  method: Method;
  service: string;
  pathname: string;
} {
  const { method, service, pathname } =
    queryName.match(
      /^((?<method>[A-Z]+) )?((?<service>[a-z0-9-]+) )?(?<pathname>.*)/
    )?.groups || {};

  return {
    method: isMethod(method) ? method : "GET",
    service: service || "unknown",
    pathname,
  };
}

export function getServiceUrl({
  service,
  config,
}: {
  service: string;
  config: Config;
}): string {
  const result = (() => {
    const { host, services } = config;
    if (service === "platform") {
      return host;
    }
    const upperCaseServiceName = (
      service[0].toLowerCase() + service.substring(1)
    )
      .replace(/([A-Z])/g, "_$1")
      .replace(/-/g, "_")
      .toUpperCase();
    return (
      services?.[service] ??
      env.get(`REACT_APP_DATA_CONTEXT_SERVICE_${upperCaseServiceName}`) ??
      env.get(`STORYBOOK_DATA_CONTEXT_SERVICE_${upperCaseServiceName}`) ??
      `${service}.api.makesuccess.io`
    );
  })();
  if (result === undefined) {
    throw new Error(`Unknown service: ${service}`);
  } else if (result === "origin") {
    return "";
  }
  return result;
}

export function getRouteQueryParameters(queryName: string): Set<string> {
  const { method, service, pathname } = parseQueryName(queryName);
  const s = new Set<string>();
  if (method === undefined && service === undefined) {
    return s;
  }
  new URL(pathname, "https://localhost").search.replace(
    re,
    function (_0, _1, _2, _3, { param }) {
      s.add(param);
      return "";
    }
  );
  return s;
}

export function getRouteUri({
  config,
  queryName,
  queryArgs = {},
}: {
  config: Config;
  queryName: string;
  queryArgs: QueryArgs;
}): string {
  const { service, pathname } = parseQueryName(queryName);
  const { queryBranch = defaultBranch } = config;
  if (service === "unknown") {
    const { dataEnvironment, host } = config;
    return getLegacyUrl({
      // @ts-expect-error TODO fix this error
      host,
      params: {
        queryName,
        queryBranch,
        ...queryArgs,
        ...(dataEnvironment ? { dataEnvironment } : {}),
      },
    });
  } else {
    if (
      service === "dashboard-data" &&
      queryArgs["queryBranch"] === undefined
    ) {
      queryArgs = { ...queryArgs, queryBranch };
    }
    const leftoverQueryArgs = { ...queryArgs };
    const origin = (() => {
      try {
        return window.location.origin;
      } catch (e) {
        return "https://example.com"; // we don't care if we can't get the origin, as we will add/remove this host (it is just here so that we can build an URL)
      }
    })();
    const serviceUrl = getServiceUrl({ service, config }) || origin;
    const url = createURL(pathname, serviceUrl);
    // step 1: find out which query args are leftovers
    url.toString().replace(re, function (_0, _1, _2, _3, { param }) {
      if (typeof param !== "string" || !(param in queryArgs)) {
        throw new Error(`Missing query argument ${param}`);
      }
      delete leftoverQueryArgs[param];
      return "";
    });
    Object.entries(leftoverQueryArgs).forEach(([name, value]) =>
      url.searchParams.set(name, String(value))
    );
    // step 2: really replace the query args
    const finalUrl = url
      .toString()
      .replace(re, function (_0, _1, _2, _3, { param }) {
        if (typeof param !== "string" || !(param in queryArgs)) {
          throw new Error(`Missing query argument ${param}`);
        }
        delete leftoverQueryArgs[param];
        return encodeURIComponent(String(queryArgs[param]));
      });
    if (finalUrl.substring(0, origin.length) === origin) {
      return finalUrl.substring(origin.length);
    } else {
      return finalUrl;
    }
  }
}
