import { memo, ReactNode, JSX } from "react";

import styled from "styled-components";

import TextXS from "../../UI/TextXS";

type ItemUIProps = {
  $active?: boolean;
};

const ItemUI = styled.div<ItemUIProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  & > svg {
    width: 24px;
    height: 24px;
    color: ${({ theme, $active }) =>
      $active ? theme.palette.secondary[500] : "#9da7a9"};
  }
`;

ItemUI.displayName = "ItemUI";

const LabelUI = styled(TextXS)<{ $active: boolean }>`
  margin-top: 3px;
  color: ${({ theme, $active }) =>
    $active ? theme.palette.secondary[500] : "#9DA7A9"};
`;

LabelUI.displayName = "LabelUI";

const DotUI = styled.span`
  border: ${({ theme }) => `1.5px solid ${theme.palette.secondary[500]}`};
  margin-top: 5px;
  border-radius: 50%;
`;

DotUI.displayName = "DotUI";

type ItemProps = {
  icon: ReactNode;
  text: string;
  active: boolean;
  onClick: () => void;
};

function Item({ icon, text, active, onClick }: ItemProps): JSX.Element {
  return (
    <ItemUI $active={active} onClick={onClick}>
      {icon}
      <LabelUI $active={active}>{text}</LabelUI>
      {active && <DotUI />}
    </ItemUI>
  );
}

export default memo(Item);
