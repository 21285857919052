import { ComponentType, Fragment, ReactNode, JSX } from "react";

import type {
  HydrationState,
  InitialEntry,
  Router as RemixRouter,
} from "@remix-run/router";

import {
  createBrowserRouter as internalCreateBrowserRouter,
  createMemoryRouter as internalCreateMemoryRouter,
  RouteObject,
} from "react-router-dom";

type BrowserRoute = Omit<RouteObject, "children"> & {
  container?: ComponentType<{ children: ReactNode }>;
  children?: BrowserRoute[];
};

function unWrappContainer(route: BrowserRoute): RouteObject {
  const { container: Container = Fragment, element, children, ...rest } = route;

  const contained = element ? <Container>{element}</Container> : undefined;
  if (children) {
    return {
      ...rest,
      index: false,
      element: contained,
      children: children.map(unWrappContainer),
    };
  }
  return {
    ...rest,
    index: true,
    element: contained,
  };
}

export function createBrowserRouter(
  appContainerElement: JSX.Element,
  routes: BrowserRoute[],
  opts?: {
    boundaryElement?: RouteObject["errorElement"];
    basename?: string;
    hydrationData?: HydrationState;
    window?: Window;
  }
): RemixRouter {
  const _routes = routes.map(unWrappContainer);
  const appRouter: Array<RouteObject> = [
    {
      element: appContainerElement,
      errorElement: opts?.boundaryElement,
      children: _routes,
    },
  ];
  return internalCreateBrowserRouter(appRouter, opts);
}

export function createMemoryRouter(
  appContainerElement: JSX.Element,
  routes: BrowserRoute[],
  opts: {
    boundaryElement?: RouteObject["errorElement"];
    basename?: string;
    hydrationData?: HydrationState;
    initialEntries?: InitialEntry[];
    initialIndex?: number;
  } = {}
): RemixRouter {
  const _routes = routes.map(unWrappContainer);
  const appRouter: Array<RouteObject> = [
    {
      element: appContainerElement,
      errorElement: opts.boundaryElement,
      children: _routes,
    },
  ];
  return internalCreateMemoryRouter(appRouter, opts);
}
