import { JSX, memo, useMemo } from "react";

import styled from "styled-components";

import { Offer } from "../../types/offers";
import Badge from "../Badge";

const tagsMap: Partial<Record<keyof Offer, [string, string]>> = {
  hasBoostTag: ["Boost", "#FF9150"],
  hasPurchaseTag: ["Purchase", "#7F71FC"],
  hasFreeTag: ["Free", "#7F71FC"],
  hasEasyTag: ["Easy", "#7DD17D"],
  hasMediumTag: ["Medium", "#7DD17D"],
  hasHardTag: ["Hard", "#7DD17D"],
  hasWebTag: ["Web", "#D17DD1"],
  hasWebMobileTag: ["WebMobile", "#D17DD1"],
  hasAndroidTag: ["Android", "#4EC8D8"],
  hasIosTag: ["Ios", "#4EC8D8"],
  hasCasinoTag: ["Casino", "#7F71FC"],
  hasGamesTag: ["Game", "#7F71FC"],
  hasRegistrationTag: ["Registration", "#7F71FC"],
  hasActionsTag: ["Actions", "#7F71FC"],
  hasDiscoveryTag: ["Discovery", "#7F71FC"],
  hasPopularTag: ["Popular", "#7F71FC"],
  hasSurveyTag: ["Survey", "#7F71FC"],
  hasShoppingTag: ["Shopping", "#7F71FC"],
  hasNewTag: ["New", "#FF9150"],
};

const TagListUI = styled.div<{ $wrap: boolean }>`
  display: flex;
  margin-top: 4px;
  flex-wrap: wrap;
  gap: 4px;
  ${({ $wrap }) => ($wrap ? `flex-wrap: wrap;` : ``)}
`;

type TagListProps = {
  offer: Offer;
  wrap?: boolean;
};

function TagList({ offer, wrap = false }: TagListProps): JSX.Element {
  const tags = useMemo(
    () =>
      Object.entries(offer).reduce<(keyof Offer)[]>((prev, [key, value]) => {
        if (key.startsWith("has") && value) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          prev.push(key as keyof Offer);
        }
        return prev;
      }, []),
    [offer]
  );

  return (
    <TagListUI $wrap={wrap}>
      {tags.map((tag) => (
        <Badge key={tag} color={tagsMap[tag]?.[1]}>
          {tagsMap[tag]?.[0]}
        </Badge>
      ))}
    </TagListUI>
  );
}

const MemoizedTagList = memo(TagList);
export { MemoizedTagList as TagList };
