import { ReactNode, memo, useEffect, useState, JSX } from "react";

import { createPortal } from "react-dom";

export type PortalBaseProps = {
  children?: ReactNode;
};

function PortalBase({ children }: PortalBaseProps): JSX.Element | null {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  const portal = document.querySelector("#portal");

  return mounted && portal ? createPortal(children, portal) : null;
}

export default memo(PortalBase);
