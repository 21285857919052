import { JSX } from "react";

import styled from "styled-components";

import { TextProps } from "../../../types/text";

type TextXSProps = TextProps;

const TextXSUI = styled.p`
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
`;

TextXSUI.displayName = "TextXSUI";

function TextXS({ className, children }: TextXSProps): JSX.Element {
  return <TextXSUI className={className}>{children}</TextXSUI>;
}

export default TextXS;
