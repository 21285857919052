import { memo, JSX } from "react";

import styled from "styled-components";

import Item from "./Item";

const NavigationBarUI = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: calc(env(safe-area-inset-bottom) + 6px);
  background-color: #fff;
`;

NavigationBarUI.displayName = "NavigationBarUI";

type NavigationBarProps = {
  page?: string;
  items: Array<{
    url: string;
    text: string;
    icon: JSX.Element;
  }>;
};

function NavigationBar({ page, items }: NavigationBarProps): JSX.Element {
  return (
    <NavigationBarUI id="joyride-3">
      {items.map(({ url, text, icon }) => (
        <Item
          key={url}
          text={text}
          icon={icon}
          active={url === page}
          url={url}
        />
      ))}
    </NavigationBarUI>
  );
}

export default memo(NavigationBar);
