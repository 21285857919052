import { memo, JSX, ChangeEventHandler } from "react";

import styled, { css } from "styled-components";

const InputUI = styled.input<{ $readonly: boolean }>`
  padding: 12px;
  border: ${({ theme }) => `1px solid ${theme.palette.neutral[300]}`};
  margin-bottom: 8px;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.black};

  &::placeholder {
    color: ${({ theme }) => theme.palette.neutral[600]};
  }

  ${({ $readonly }) =>
    $readonly &&
    css`
      pointer-events: none;
      border: ${({ theme }) => `1px solid ${theme.palette.secondary[500]}`};
      background-color: ${({ theme }) => theme.palette.secondary[50]};
      color: ${({ theme }) => theme.palette.secondary[500]};
    `}
`;

export type InputProps = {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  defaultValue?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  readonly?: boolean;
  type?: string;
};

function Input({
  onChange,
  defaultValue,
  value,
  name,
  placeholder,
  type = "text",
  readonly = false,
}: InputProps): JSX.Element {
  return (
    <InputUI
      name={name}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      type={type}
      $readonly={readonly}
    />
  );
}

export default memo(Input);
