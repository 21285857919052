import { memo, JSX } from "react";

import styled from "styled-components";

import { ReactComponent as BeggingHandCoinIcon } from "../../../assets/icons/curve/begging-hand-coin.svg";
import { ReactComponent as ChatIcon } from "../../../assets/icons/curve/chat.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/icons/curve/document.svg";
import { ReactComponent as InfosIcon } from "../../../assets/icons/curve/infos-square.svg";
import Portal from "../../HOC/Portal";
import { GetHelp } from "../GetHelp/GetHelp";
import Row from "../Row";

const RowsUI = styled.div``;

RowsUI.displayName = "RowsUI";

const UnstyledLinkUI = styled.a`
  all: unset;
`;

UnstyledLinkUI.displayName = "UnstyledLinkUI";

export type RowsProps = {
  onUseTermsClick: () => void;
  onSupportClick: () => void;
  onGetStartedClick: () => void;
  onDrawerToggle: () => void;
  drawerDisplay: boolean;
  hasFallback: boolean;
};

function Rows({
  onUseTermsClick,
  onSupportClick,
  onGetStartedClick,
  drawerDisplay,
  onDrawerToggle,
  hasFallback,
}: RowsProps): JSX.Element {
  return (
    <RowsUI>
      <Row icon={<DocumentIcon />} text="Use Terms" onClick={onUseTermsClick} />
      <Row icon={<ChatIcon />} text="Support" onClick={onSupportClick} />
      {hasFallback ? null : (
        <Row
          icon={<InfosIcon />}
          text="Get Started"
          onClick={onGetStartedClick}
        />
      )}
      <UnstyledLinkUI
        href="mailto:hello@farly.io?subject=Become a Farly publisher"
        target="_blank"
        rel="noreferrer"
      >
        <Row
          icon={<BeggingHandCoinIcon />}
          text="Want to become a publisher?"
        />
      </UnstyledLinkUI>
      <Portal>
        <GetHelp
          drawerDisplay={drawerDisplay}
          onDrawerToggle={onDrawerToggle}
        />
      </Portal>
    </RowsUI>
  );
}

export default memo(Rows);
