import { memo, JSX, ChangeEventHandler } from "react";

import styled from "styled-components";

const TextAreaUI = styled.textarea`
  padding: 12px;
  border: ${({ theme }) => `1px solid ${theme.palette.neutral[300]}`};
  margin-bottom: 8px;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.black};
  resize: vertical;
  font-family: inherit;

  &::placeholder {
    color: ${({ theme }) => theme.palette.neutral[600]};
  }
`;

export type TextAreaProps = {
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  value?: string;
  name?: string;
  placeholder?: string;
};

function TextArea({
  onChange,
  value,
  name,
  placeholder,
}: TextAreaProps): JSX.Element {
  return (
    <TextAreaUI
      name={name}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  );
}

const MemoizedTextArea = memo(TextArea);
export { MemoizedTextArea as TextArea };
