import { memo, JSX } from "react";

import styled from "styled-components";

import background from "../../assets/background.png";
import TextXXL from "../UI/TextXXL";

const HeaderUI = styled.div<{ $shrinked: boolean }>`
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  height: ${({ $shrinked }) => ($shrinked ? "65px" : "90px")};
  transition: ${({ theme }) => theme.transition.regular};
`;

HeaderUI.displayName = "HeaderUI";

const ContainerUI = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

ContainerUI.displayName = "ContainerUI";

const BackgroundUI = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

BackgroundUI.displayName = "BackgroundUI";

const TextUI = styled(TextXXL)<{ $shrinked: boolean }>`
  z-index: 3;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ $shrinked }) => ($shrinked ? "18px" : "28px")};
  transition: ${({ theme }) => theme.transition.regular};
`;

TextUI.displayName = "TextUI";

type HeaderProps = {
  text: string;
  shrinked: boolean;
};

function Header({ text, shrinked }: HeaderProps): JSX.Element {
  return (
    <HeaderUI $shrinked={shrinked}>
      <ContainerUI>
        <BackgroundUI src={background} />
        <TextUI $shrinked={shrinked}>{text}</TextUI>
      </ContainerUI>
    </HeaderUI>
  );
}

export default memo(Header);
