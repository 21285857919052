const defaultBranch = import.meta.env.VITE_DATA_CONTEXT_DEFAULT_BRANCH;

function get(key: string): string | undefined {
  return import.meta.env[key];
}

export const env = {
  defaultBranch,
  get,
};
