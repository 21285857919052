import { JSX, memo, useMemo } from "react";

import { useSearchParam } from "@mobsuccess-devops/react-router";

import styled from "styled-components";

import { Offer } from "../../../types/offers";
import TextXL from "../../UI/TextXL";

export const Button = styled.button`
  all: unset;
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.secondary[500]};
  border-radius: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary[700]};
  }
`;

const ButtonTextUI = styled(TextXL)`
  color: ${({ theme }) => theme.colors.white};
`;

const MoneyIconUI = styled.img`
  width: 30px;
`;

type OfferActionButtonProps = {
  offer: Offer;
  onClick?: () => void;
};

function OfferActionButton({
  offer,
  onClick,
}: OfferActionButtonProps): JSX.Element {
  const [fallback] = useSearchParam("fallback");

  const ctaText = useMemo(() => {
    if (fallback) {
      return "Download for free";
    }
    if (offer?.hasSurveyTag) {
      return "I take part";
    }
    if (offer?.hasDiscoveryTag) {
      return "ENJOY 👉";
    }
    return `Get ${offer?.rewardAmount} ${
      offer?.moneyIcon ? "" : offer?.moneyName
    } in total`;
  }, [offer, fallback]);

  return (
    <Button onClick={onClick}>
      <ButtonTextUI>{ctaText}</ButtonTextUI>
      {!offer.hasSurveyTag && !offer?.hasDiscoveryTag && offer?.moneyIcon ? (
        <MoneyIconUI src={offer.moneyIcon} />
      ) : null}
    </Button>
  );
}

const MemoizedOfferActionButton = memo(OfferActionButton);
export { MemoizedOfferActionButton as OfferActionButton };
