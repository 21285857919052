import { useCallback, useMemo } from "react";

import { useLocation } from "react-router-dom";

import { SearchParams, ParamTypeMap } from "../class/search-params";
import { useNavigate } from "../navigation";
import { useListeningRef } from "../references";
import { useRouterContext } from "../router";

type SearchParamsState = [
  searchParam: SearchParams,
  setSearchParam: (
    newValue:
      | URLSearchParams
      | Record<string, ParamTypeMap[keyof ParamTypeMap]>
      | ((prev: SearchParams) => SearchParams),
    options?: { replace?: boolean; state?: unknown }
  ) => void
];

function useSearchParams(): SearchParamsState {
  const { dispatchNavigationEvent } = useRouterContext();
  const { search } = useLocation();
  const navigate = useNavigate();

  const searchParams = useMemo(() => {
    return new SearchParams(search);
  }, [search]);

  const searchParamsRef = useListeningRef(searchParams);

  const handleChangeSearchParams = useCallback<SearchParamsState[1]>(
    (newValue, options) => {
      const prev = searchParamsRef.current;
      let next: SearchParams;
      if (typeof newValue === "function") {
        next = newValue(new SearchParams(prev));
      } else if (newValue instanceof URLSearchParams) {
        next = new SearchParams(newValue);
      } else {
        const searchParams = new SearchParams(prev);
        Object.entries(newValue).forEach(([key, value]) => {
          if (value) {
            if (typeof value === "object" && Array.isArray(value)) {
              return searchParams.setTyped(key, value, { type: "array" });
            }
            searchParams.setTyped(key, value, {
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              type: typeof value as keyof ParamTypeMap,
            });
          } else {
            searchParams.delete(key);
          }
        });
        next = searchParams;
      }
      navigate(
        {
          search: next.toString(),
        },
        options
      );
      dispatchNavigationEvent();
    },
    [dispatchNavigationEvent, navigate, searchParamsRef]
  );

  return [searchParams, handleChangeSearchParams];
}

export default useSearchParams;
