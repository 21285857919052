import { memo, JSX } from "react";

import styled from "styled-components";

import type { Offer } from "../../../../types/offers";
import TextMD from "../../../UI/TextMD";
import TextSM from "../../../UI/TextSM";
import TextXS from "../../../UI/TextXS";

const EligibilityWrapperUI = styled.div`
  background-color: #f1f2f8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

const ListUI = styled.ul`
  padding-left: 24px;
`;

type HelpEligibilityProps = {
  offer?: Offer;
};

function HelpEligibility({ offer }: HelpEligibilityProps): JSX.Element | null {
  if (!offer) {
    return null;
  }

  switch (true) {
    case offer.hasCasinoTag:
      return (
        <EligibilityWrapperUI>
          <TextMD>Eligibility terms</TextMD>
          <TextSM>Betting - Deposit - Casino Offers</TextSM>
          <TextXS>
            Attention, if you have completed a betting, deposit, or casino
            offer, please take note of the following information. To evaluate
            your case in the best possible manner, we require the following
            information to be sent to us. If any of the following information is
            missing, it will not be possible to conclude the analysis:
          </TextXS>
          <ListUI>
            <li>
              <TextXS>Welcome email along with the date</TextXS>
            </li>
            <li>
              <TextXS>First-time deposit receipt along with its date</TextXS>
            </li>
            <li>
              <TextXS>
                Proof that the offer was in progress in Farly offerwall
              </TextXS>
            </li>
            <li>
              <TextXS>
                Proof that the amount deposited has also been betted
              </TextXS>
            </li>
          </ListUI>
          <TextXS>
            Without this information, we cannot proceed with the analysis of
            your case.
          </TextXS>
        </EligibilityWrapperUI>
      );

    case offer.hasGamesTag:
      return (
        <EligibilityWrapperUI>
          <TextMD>Eligibility terms</TextMD>
          <TextSM>Gaming Campaigns</TextSM>
          <TextXS>
            Attention, if you have completed a gaming campaign, please take note
            of the following information. To evaluate your case in the best
            possible manner, we require the following information to be sent to
            us. If any of the following information is missing, it will not be
            possible to conclude the analysis.
          </TextXS>
          <ListUI>
            <li>
              <TextXS>
                Welcome email along with the date (sent when users create their
                accounts)
              </TextXS>
            </li>
            <li>
              <TextXS>
                Proof of in-game purchases or first-time credit usage, if
                applicable, along with the date
              </TextXS>
            </li>
            <li>
              <TextXS>
                Proof of gameplay activity demonstrating that you have reached
                the requested level or completed the required tasks (if these
                were the terms of the campaign)
              </TextXS>
            </li>
          </ListUI>
          <TextXS>
            Without this information, we cannot proceed with the analysis of
            your case.
          </TextXS>
        </EligibilityWrapperUI>
      );

    case offer.hasRegistrationTag:
      return (
        <EligibilityWrapperUI>
          <TextMD>Eligibility terms</TextMD>
          <TextSM>Registration/Signup Campaigns</TextSM>
          <TextXS>
            Attention, if you have completed a registration campaign, please
            take note of the following information. For us to evaluate your case
            in the best possible manner, we require the following information to
            be sent to us. If any of the following information is missing, it
            will not be possible to conclude the analysis.
          </TextXS>
          <ListUI>
            <li>
              <TextXS>Welcome email along with the date</TextXS>
            </li>
            <li>
              <TextXS>
                Confirmation of completed registration, such as a screenshot of
                your profile page, the confirmation screen, or email after the
                registration process.
              </TextXS>
            </li>
          </ListUI>
          <TextXS>
            Without this information, we cannot proceed with the analysis of
            your case.
          </TextXS>
        </EligibilityWrapperUI>
      );

    default:
      return (
        <EligibilityWrapperUI>
          <TextMD>Eligibility terms</TextMD>
          <TextSM>General Campaigns</TextSM>
          <TextXS>
            Attention, if you have completed a campaign involving action or
            engagement activity, please take note of the following information.
            To evaluate your case in the best possible manner, we require the
            following information to be sent to us. If any of the following
            information is missing, it will not be possible to conclude the
            analysis.
          </TextXS>
          <ListUI>
            <li>
              <TextXS>
                Proof that you&apos;re a new user - Welcome Email, Welcome
                message in the app;
              </TextXS>
            </li>
            <li>
              <TextXS>
                Evidence of the completed activity. This could be a screenshot
                or other proof of completing the specified action, such as
                engaging with content, participating in a poll or survey,
                downloading an app, or any other incentivized activity outlined
                in the campaign terms.
              </TextXS>
            </li>
          </ListUI>
          <TextXS>
            Without this information, we cannot proceed with the analysis of
            your case.
          </TextXS>
        </EligibilityWrapperUI>
      );
  }
}

const MemoizedHelpEligibility = memo(HelpEligibility);
export { MemoizedHelpEligibility as HelpEligibility };
