import { memo, JSX, useCallback } from "react";

import styled from "styled-components";

import { ReactComponent as GoldCoinIcon } from "../../../assets/icons/custom/gold-coin.svg";
import { useSelectedOfferAtom } from "../../../features/atoms/offers";
import { Offer } from "../../../types/offers";
import TextSM from "../../UI/TextSM";
import TextXS from "../../UI/TextXS";

const OfferCardUI = styled.div`
  display: flex;
  flex: 0 0 96px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 10px 0;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
`;

const TextSMUI = styled(TextSM)`
  text-align: center;
  margin-bottom: auto;
`;

const LogoContainerUI = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
`;

const OfferPointsUI = styled(TextXS)`
  color: ${({ theme }) => theme.palette.neutral[500]};
  text-align: center;
`;

const MoneyIconContainerUI = styled.img`
  width: 24px;
`;

type MediumOfferProps = {
  offer: Offer;
};

function MediumOffer({ offer }: MediumOfferProps): JSX.Element {
  const [, setSelectedOffer] = useSelectedOfferAtom();

  const handleClick = useCallback(() => {
    setSelectedOffer(offer);
  }, [offer, setSelectedOffer]);

  return (
    <OfferCardUI onClick={handleClick}>
      <LogoContainerUI src={offer.icone} />
      <TextSMUI>{offer.name}</TextSMUI>
      {offer.moneyIcon ? (
        <MoneyIconContainerUI src={offer.moneyIcon} />
      ) : (
        <GoldCoinIcon />
      )}
      <OfferPointsUI>
        {offer.rewardAmount} {offer.moneyName}
      </OfferPointsUI>
    </OfferCardUI>
  );
}

const MemoizedMediumOffer = memo(MediumOffer);
export { MemoizedMediumOffer as MediumOffer };
