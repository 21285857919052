import { JSX } from "react";

import styled from "styled-components";

import { TextProps } from "../../../types/text";

type TextSMProps = TextProps;

const TextSMUI = styled.p`
  color: ${({ theme }) => theme.palette.neutral[900]};
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`;

TextSMUI.displayName = "TextSMUI";

function TextSM({ className, children }: TextSMProps): JSX.Element {
  return <TextSMUI className={className}>{children}</TextSMUI>;
}

export default TextSM;
