import { memo, JSX, useCallback } from "react";

import styled from "styled-components";

import { useSelectedOfferAtom } from "../../../features/atoms/offers";
import { Offer } from "../../../types/offers";

const LogoUI = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
`;

export type SmallOfferProps = {
  offer: Offer;
};

function SmallOffer({ offer }: SmallOfferProps): JSX.Element {
  const [, setSelectedOffer] = useSelectedOfferAtom();

  const handleClick = useCallback(() => {
    setSelectedOffer(offer);
  }, [offer, setSelectedOffer]);

  return <LogoUI onClick={handleClick} src={offer.icone} alt="" />;
}

const MemoizedLargeOffer = memo(SmallOffer);
export { MemoizedLargeOffer as SmallOffer };
