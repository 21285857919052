const theme = {
  animation: {
    short: ".3s",
    regular: ".4s",
    long: ".5s",
    easing: "cubic-bezier(0.8, 1, 0.17, 1);",
  },
  transition: {
    regular: "all .4s cubic-bezier(0.8, 1, 0.17, 1);",
  },
  colors: {
    black: "#000000",
    white: "#FFFFFF",
  },
  boxShadows: {
    xs: "0px 4px 8px rgba(0, 0, 0, 0.1);",
    sm: "0px 6px 12px rgba(0, 0, 0, 0.1);",
    md: "0px 8px 16px rgba(0, 0, 0, 0.1);",
    lg: "0px 10px 20px rgba(0, 0, 0, 0.1);",
    xl: "0px 12px 24px rgba(0, 0, 0, 0.1);",
  },
};

const palettes = {
  appMarketing: {
    neutral: {
      50: "#FAFBFB",
      100: "#F0F2F2",
      200: "#E2E5E5",
      300: "#D3D7D8",
      400: "#C4CACB",
      500: "#9DA7A9",
      600: "#728082",
      700: "#4C5557",
      800: "#262B2B",
      900: "#131516",
    },
    primary: {
      50: "#FBFDFE",
      100: "#F2FAFB",
      200: "#E4F5F7",
      300: "#D7F0F3",
      400: "#C9EBEF",
      500: "#A5DDE5",
      600: "#59C0CF",
      700: "#2C8B99",
      800: "#16464C",
      900: "#0B2326",
    },
    secondary: {
      50: "#F9F8FF",
      100: "#ECEAFF",
      200: "#D9D4FE",
      300: "#C5BFFE",
      400: "#B2AAFD",
      500: "#7F71FC",
      600: "#5744FB",
      700: "#1504B3",
      800: "#0B0259",
      900: "#05012D",
    },
    success: {
      50: "#F4FBF4",
      100: "#DEF4DE",
      200: "#BEE8BE",
      300: "#9DDD9D",
      400: "#7DD17D",
      500: "#51C251",
      600: "#369936",
      700: "#246624",
      800: "#123312",
      900: "#091909",
    },
    warning: {
      50: "#FFFAF0",
      100: "#FFF1D1",
      200: "#FFE4A3",
      300: "#FFD675",
      400: "#FFC847",
      500: "#FFB60A",
      600: "#C78C00",
      700: "#855D00",
      800: "#422F00",
      900: "#211700",
    },
    danger: {
      50: "#FEF5F4",
      100: "#FBE2DF",
      200: "#F8C4BF",
      300: "#F4A79F",
      400: "#F0897F",
      500: "#E63B2A",
      600: "#B72415",
      700: "#7A180E",
      800: "#3D0C07",
      900: "#1E0604",
    },
    colors: {
      pink: {
        50: "#FFFAFB",
        100: "#FFF0F3",
        200: "#FFE2E7",
        300: "#FFD3DB",
        400: "#FFC4CE",
        500: "#FF9DAE",
        600: "#FF3659",
        700: "#CE0024",
        800: "#670012",
        900: "#330009",
      },
      orange: {
        50: "#FFFAF6",
        100: "#FFEEE5",
        200: "#FFDECA",
        300: "#FFCEB0",
        400: "#FFBD96",
        500: "#FF9150",
        600: "#FB5D00",
        700: "#A83E00",
        800: "#541F00",
        900: "#2A1000",
      },
      yellow: {
        50: "#FFFEF9",
        100: "#FEFCEC",
        200: "#FDF9D8",
        300: "#FCF6C5",
        400: "#FBF3B2",
        500: "#F9EB7E",
        600: "#F5DD24",
        700: "#B3A008",
        800: "#5A5004",
        900: "#2D2802",
      },
    },
  },
};

export { palettes };

export default theme;
