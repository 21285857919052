import { useCallback } from "react";

// import { useAtom, useGetAtom } from "@mobsuccess-devops/react-data-context";
import { useAtom, useGetAtom, type Atom } from "@mobsuccess-devops/react-atom";

import { Offer } from "../../types/offers";

const offersAtomId = "offers";

const discoveryOffersAtomId = "discoveryOffers";

const selectedOfferAtomId = "selectedOffer";

const isFetchingAtomId = "isFetching";

const isFetchingDiscoveryAtomId = "isFetchingDiscovery";

export function useOffersAtom(): Atom<Offer[]> {
  return useAtom<Offer[]>(offersAtomId, []);
}

export function useDiscoveryOffersAtom(): Atom<Offer[]> {
  return useAtom<Offer[]>(discoveryOffersAtomId, []);
}

export function useSelectedOfferAtom(): Atom<Offer | null> {
  return useAtom<Offer | null>(selectedOfferAtomId, null);
}

export function useIsFetchingOffersAtom(): Atom<boolean> {
  return useAtom(isFetchingAtomId, false);
}

export function useIsFetchingDiscoveryOffersAtom(): Atom<boolean> {
  return useAtom(isFetchingDiscoveryAtomId, false);
}

export function useGetData(id: string): () => Promise<Offer[]> {
  const getAtom = useGetAtom();
  return useCallback(async () => {
    const [value] = await getAtom<Offer[]>(id);
    return value;
  }, [getAtom, id]);
}

export function useGetIsFetching(id: string): () => Promise<boolean> {
  const getAtom = useGetAtom();
  return useCallback(async () => {
    const [value] = await getAtom<boolean>(id);
    return value;
  }, [getAtom, id]);
}

export function useGetOffers(): () => Promise<Offer[]> {
  return useGetData(offersAtomId);
}

export function useGetDiscoveryOffers(): () => Promise<Offer[]> {
  return useGetData(discoveryOffersAtomId);
}

export function useGetIsFetchingOffers(): () => Promise<boolean> {
  return useGetIsFetching(isFetchingAtomId);
}

export function useGetIsFetchingDiscoveryOffers(): () => Promise<boolean> {
  return useGetIsFetching(isFetchingDiscoveryAtomId);
}
