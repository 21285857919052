type QueueItem = {
  id: string;
  payload: unknown;
  resolver(payload: unknown): void;
};

export class ConcurrencyManager {
  private queue: QueueItem[] = [];
  private running: Map<string, QueueItem> = new Map();

  constructor(
    private options: { maxConcurrent: number } = { maxConcurrent: 10 }
  ) {
    if (options.maxConcurrent < 1) {
      throw "Concurrency Manager Error: minimun concurrent requests is 1";
    }
    this.enqueue = this.enqueue.bind(this);
  }

  private push(reqHandler: QueueItem): void {
    this.queue.push(reqHandler);
    this.shift();
  }

  private shift(): void {
    if (this.running.size >= this.options.maxConcurrent) {
      return;
    }
    const queued = this.queue.shift();
    if (!queued) {
      return;
    }
    queued.resolver(queued.payload);
    this.running.set(queued.id, queued);
  }

  public enqueue<T>(id: string, payload: T): Promise<T> {
    return new Promise<T>((resolver) => {
      this.push({
        id,
        payload,
        resolver() {
          resolver(payload);
        },
      });
    });
  }

  public dequeue(id: string): void {
    if (this.running.has(id)) {
      this.running.delete(id);
    }
    this.shift();
  }
}
