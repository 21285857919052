import { ReactNode, memo, useCallback, JSX } from "react";

import {
  useNavigate,
  useSearchParams,
} from "../../../../../../packages/react-router/src";

import Item from "./Item";

type ItemBaseProps = {
  active?: boolean;
  icon: ReactNode;
  text?: string;
  url?: string;
};

function ItemBase({
  active = false,
  icon,
  text = "",
  url,
}: ItemBaseProps): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleClick = useCallback(() => {
    navigate({
      pathname: "/" + url,
      search: searchParams.toString(),
    });
  }, [navigate, url, searchParams]);

  return <Item active={active} icon={icon} text={text} onClick={handleClick} />;
}

export default memo(ItemBase);
