import { memo, useEffect, useState, JSX } from "react";

import Header from "./Header";

type HeaderBaseProps = {
  text: string;
};

function HeaderBase({ text }: HeaderBaseProps): JSX.Element {
  const [shrinked, setShrinked] = useState(false);

  useEffect(() => {
    const shrinkHandler = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setShrinked(true);
      }
      if (
        document.body.scrollTop < 4 &&
        document.documentElement.scrollTop < 4
      ) {
        setShrinked(false);
      }
    };
    window.addEventListener("scroll", shrinkHandler);
    return () => window.removeEventListener("scroll", shrinkHandler);
  }, [shrinked]);

  return <Header text={text} shrinked={shrinked} />;
}

export default memo(HeaderBase);
