/* eslint-disable @mobsuccess-devops/mobsuccess/variables */
import {
  memo,
  useState,
  useCallback,
  ChangeEventHandler,
  useMemo,
  useEffect,
  JSX,
} from "react";

import { useSearchParam } from "@mobsuccess-devops/react-router";

import styled, { keyframes } from "styled-components";

import { useFetchOffers } from "../../../features/actions/offers";
import { useOffersAtom } from "../../../features/atoms/offers";
import Input from "../../UI/Input";
import Select from "../../UI/Select";
import { TextArea } from "../../UI/TextArea/TextArea";
import TextLG from "../../UI/TextLG";
import TextSM from "../../UI/TextSM";
import TextXL from "../../UI/TextXL";
import TextXS from "../../UI/TextXS";
import TextXXL from "../../UI/TextXXL";

import { HelpEligibility } from "./HelpEligibility/HelpEligibility";

const slide = (panelWidth?: number) => keyframes`
0% {
  transform: translateY(${panelWidth}vh);
}
100% {
  transform: translateY(0px);
}`;

const GetHelpUI = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  align-items: flex-end;
  background-color: rgb(0, 0, 0);
  animation: appear 0.3s ease-in-out;

  @keyframes appear {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(0, 0, 0, 1);
    }
  }

  &::after {
    position: absolute;
    z-index: 102;
    top: 36px;
    right: 0;
    left: 0;
    display: block;
    width: 92%;
    height: 20px;
    margin-right: auto;
    margin-left: auto;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 10px 10px 0px 0px;
    clear: both;
    content: "";
    animation: ${slide(68)} 0.5s cubic-bezier(0.8, 1, 0.17, 1);
    opacity: 0.4;
  }
`;

const ContentUI = styled.div`
  position: relative;
  z-index: 102;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 90vh;
  /* this is only interpreted by recent iOS Safari ! */
  height: 94dvh; /* stylelint-disable-line unit-no-unknown, unit-allowed-list */
  box-sizing: border-box;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 12px;
  animation: ${slide(68)} 0.5s cubic-bezier(0.8, 1, 0.17, 1);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px 16px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
`;

const TopUI = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CancelUI = styled.div`
  p {
    font-weight: 500;
  }
`;

const RequiredUI = styled(TextSM)`
  color: ${({ theme }) => theme.palette.secondary[500]};
  position: absolute;
  inset: 0 0 auto auto;
`;

const ButtonUI = styled.button`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  border: none;
  margin-top: 24px;
  background-color: ${({ theme }) => theme.palette.secondary[500]};
  border-radius: 10px;
  p {
    color: ${({ theme }) => theme.colors.white};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.palette.neutral[300]};
  }
`;

export type GetHelpProps = {
  drawerDisplay: boolean;
  onDrawerToggle: () => void;
};

function GetHelp({
  drawerDisplay,
  onDrawerToggle,
}: GetHelpProps): JSX.Element | null {
  const [userId] = useSearchParam("userid", {
    defaultValue: "",
  });
  const [offers] = useOffersAtom();
  useFetchOffers();
  const [offerOptions, setOfferOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [formData, setFormData] = useState({
    offer: "",
    country: "",
    device: "",
    firstClickDate: "",
    description: "",
  });

  const selectedOffer = useMemo(
    () => offers.find((offer) => offer.id === formData.offer),
    [formData.offer, offers]
  );

  const deviceOptions = useMemo(
    () => [
      { label: "Android", value: "android" },
      { label: "iOS", value: "ios" },
      { label: "Windows/Desktop", value: "windows/desktop" },
    ],
    []
  );

  const geoOptions = useMemo(
    () =>
      selectedOffer?.countries.map((country) => ({
        label: country,
        value: country,
      })) ?? [],
    [selectedOffer]
  );

  useEffect(() => {
    if (!offers?.length) {
      return;
    }
    setOfferOptions(
      offers
        .filter(
          (offer) =>
            (offer.status === "in_progress" || offer.status === "completed") &&
            !offer.hasDiscoveryTag
        )
        .map((offer) => ({
          label: offer.name,
          value: offer.id,
        }))
    );
    const { id, os, countries } = offers[0];
    setFormData((prev) => ({
      ...prev,
      offer: id,
      device: os,
      country: countries[0],
    }));
  }, [offers]);

  const handleChange = useCallback<
    ChangeEventHandler<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  >(
    (e) => {
      const { name, value } = e.target;
      if (name === "offer") {
        const offer = offers.find((offer) => offer.id === value);
        if (!offer) {
          return;
        }
        setFormData({
          ...formData,
          offer: value,
          device: offer.os,
          country: offer.countries[0],
        });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    },
    [formData, setFormData, offers]
  );

  const handleSubmit = useCallback(() => {
    const email = selectedOffer?.supportEmail || "support@farly.io";
    const offerId = formData.offer;
    const subject = encodeURIComponent(
      `Help ${offerId ? `with offer ${offerId}` : ""}`
    );

    const bodyParts = [
      `Farly Support Request ${offerId ? `- Reference ${offerId}` : ""}`,
      `User ID: #${userId}`,
      `Offer name: ${selectedOffer?.name}`,
    ];
    if (formData.country) {
      bodyParts.push(`GEO/Country: ${formData.country}`);
    }
    if (formData.device) {
      bodyParts.push(`Device: ${formData.device}`);
    }
    if (formData.firstClickDate) {
      bodyParts.push(`Date of First Click: ${formData.firstClickDate}`);
    }
    if (formData.description) {
      bodyParts.push(`Brief description: ${formData.description}`);
    }

    const body = encodeURIComponent(bodyParts.join("\n\n"));
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  }, [userId, formData, selectedOffer]);

  if (!drawerDisplay) {
    return null;
  }

  return (
    <GetHelpUI>
      <ContentUI>
        <TopUI>
          <TextXXL>Get Help</TextXXL>
          <CancelUI onClick={onDrawerToggle}>
            <TextLG>Cancel</TextLG>
          </CancelUI>
        </TopUI>
        <FieldLabelWrapper label="User ID">
          <Input defaultValue={`#${userId}`} readonly />
        </FieldLabelWrapper>
        <FieldLabelWrapper label="Offer you need help with" required>
          <Select
            options={offerOptions}
            value={formData.offer}
            onChange={handleChange}
            name="offer"
            placeholder="Please select your offer"
          />
        </FieldLabelWrapper>
        <FieldLabelWrapper label="GEO/Country">
          <Select
            options={geoOptions}
            value={formData.country}
            onChange={handleChange}
            name="country"
            placeholder="Please select your country"
          />
        </FieldLabelWrapper>
        <FieldLabelWrapper label="Device">
          <Select
            options={deviceOptions}
            value={formData.device}
            onChange={handleChange}
            name="device"
            placeholder="Please select your device"
          />
        </FieldLabelWrapper>
        <FieldLabelWrapper label="Date of First Click">
          <Input
            value={formData.firstClickDate}
            type="date"
            name="firstClickDate"
            onChange={handleChange}
          />
        </FieldLabelWrapper>
        <FieldLabelWrapper label="Brief description of the situation">
          <TextArea
            value={formData.description}
            onChange={handleChange}
            name="description"
            placeholder="Please enter a brief description"
          />
        </FieldLabelWrapper>
        <HelpEligibility offer={selectedOffer} />
        <ButtonUI onClick={handleSubmit} disabled={!formData.offer}>
          <TextXL>Send</TextXL>
        </ButtonUI>
        <TextXS>
          After you send your message, an expert will reach out to your email
          within the next 48 hours.
        </TextXS>
      </ContentUI>
    </GetHelpUI>
  );
}

const FieldLabelWrapperUI = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

type FieldLabelWrapperProps = {
  children: JSX.Element;
  label: string;
  required?: boolean;
};

function FieldLabelWrapper({
  children,
  label,
  required,
}: FieldLabelWrapperProps): JSX.Element {
  return (
    <FieldLabelWrapperUI>
      <TextSM>{label}</TextSM>
      {children}
      {required && <RequiredUI>Required</RequiredUI>}
    </FieldLabelWrapperUI>
  );
}

const MemoizedGetHelp = memo(GetHelp);
export { MemoizedGetHelp as GetHelp };
