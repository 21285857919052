import { useEffect } from "react";

import {
  init,
  BrowserOptions,
  BrowserTracing,
  reactRouterV6Instrumentation,
  captureEvent,
} from "@sentry/react";

import {
  useLocation,
  matchRoutes,
  useNavigationType,
  createRoutesFromChildren,
} from "react-router-dom";

import {
  ConsentManager,
  createConsentManagerTransport,
} from "./consent-manager";
import { env } from "./env";
import "./fetch";
import "./xhr";

function getTags() {
  const { pr } =
    window.location.hostname.match(/^(?<pr>pr-[_0-9]+)\./)?.groups ?? {};
  return {
    pr,
    whoami: env.whoami,
  };
}

function getSampleRate() {
  const lsSampleRate = localStorage.getItem("sentry_sample_rate");
  if (lsSampleRate && !isNaN(+lsSampleRate)) {
    return +lsSampleRate;
  } else if (lsSampleRate) {
    localStorage.removeItem("sentry_sample_rate");
  }
  return env.sampleRate ?? 1;
}

export function initSentry({
  dsn,
  environment = "local",
  catchWindowError = true,
  consentManager = new ConsentManager({
    isConsenting: false,
    awaitForConsent: false,
    isConsentRequired: false,
  }),
}: Pick<BrowserOptions, "dsn" | "environment"> & {
  consentManager?: ConsentManager;
  catchWindowError?: boolean;
} = {}): BrowserOptions {
  const sampleRate = getSampleRate();

  const browserTracing = new BrowserTracing({
    startTransactionOnLocationChange: false,
    tracingOrigins: ["localhost", /^https:\/\/.*api.makesuccess.io/],
    routingInstrumentation: reactRouterV6Instrumentation(
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    ),
  });

  consentManager.setEnvironment(environment);

  if (catchWindowError) {
    window.addEventListener("error", (event) => {
      if (event.error) {
        captureEvent(event.error);
      }
    });
  }

  const sentryOptions: BrowserOptions = {
    dsn,
    sampleRate,
    environment,
    release: env.release,
    autoSessionTracking: false,
    tracesSampleRate: sampleRate,
    integrations: [browserTracing],
    initialScope: { tags: getTags() },
    transport: createConsentManagerTransport(consentManager),
  };

  init(sentryOptions);

  return sentryOptions;
}

export { ConsentManager };
