import { memo, useCallback, useMemo, useState, JSX } from "react";

import {
  useNavigate,
  useSearchParams,
} from "../../../../../../packages/react-router/src";
import { useTourIntroModalAtom } from "../../../features/atoms/global";

import Rows from "./Rows";

function RowsBase(): JSX.Element {
  const [, setIntroModal] = useTourIntroModalAtom();
  const [drawerDisplay, setDrawerDisplay] = useState(false);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const handleUseTermsClick = useCallback(() => {
    window.location.href = "https://www.mobsuccess.com/en/privacy";
  }, []);

  const handleSupportClick = useCallback(() => {
    setDrawerDisplay(true);
  }, []);

  const handleGetStartedClick = useCallback(() => {
    navigate("/my-offers");
    setIntroModal(true);
  }, [setIntroModal, navigate]);

  const handleDrawerToggle = useCallback(() => {
    setDrawerDisplay((prevState) => !prevState);
  }, []);

  const hasFallback = useMemo(() => {
    return searchParams.has("fallback");
  }, [searchParams]);

  return (
    <Rows
      onGetStartedClick={handleGetStartedClick}
      onUseTermsClick={handleUseTermsClick}
      onSupportClick={handleSupportClick}
      drawerDisplay={drawerDisplay}
      onDrawerToggle={handleDrawerToggle}
      hasFallback={hasFallback}
    />
  );
}

export default memo(RowsBase);
