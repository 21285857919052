import { JSX, memo, useMemo } from "react";

import { MediumOfferList } from "../components/MediumOfferList/MediumOfferList";
import { TourIntroModal } from "../components/TourIntroModal/TourIntroModal";
import { PageLayout } from "../components/UI/PageLayout/PageLayout";
import { useFetchOffers } from "../features/actions/offers";
import { useOffersAtom } from "../features/atoms/offers";

function MyOffers(): JSX.Element {
  useFetchOffers();
  const [offers] = useOffersAtom();

  const inProgressOffers = useMemo(
    () => offers.filter(({ status }) => status === "in_progress"),
    [offers]
  );

  const completedOffers = useMemo(
    () => offers.filter(({ status }) => status === "completed"),
    [offers]
  );

  return (
    <PageLayout>
      <TourIntroModal />
      <MediumOfferList title="Continue Playing 🎮" offers={inProgressOffers} />
      <MediumOfferList title="Completed 🏆" offers={completedOffers} />
    </PageLayout>
  );
}

export default memo(MyOffers);
