import { ReactNode, memo, useEffect, JSX } from "react";

import {
  useNavigate,
  useSearchParam,
} from "../../../../../../packages/react-router/src";

type EnsureFromSearchParamBaseProps = {
  children?: ReactNode;
};

function EnsureFromSearchParamBase({
  children,
}: EnsureFromSearchParamBaseProps): JSX.Element | null {
  const [from, setFrom] = useSearchParam("from");
  const [fallback] = useSearchParam("fallback");
  const navigate = useNavigate();

  useEffect(() => {
    if (fallback) {
      navigate({
        pathname: "/categories",
        search: "?fallback=true&from=wallv2",
      });
    } else if (from !== "wallv2") {
      setFrom("wallv2");
    }
  }, [from, setFrom, fallback, navigate]);

  if (from !== "wallv2") {
    return null;
  }

  return <>{children}</>;
}

export default memo(EnsureFromSearchParamBase);
