import { JSX } from "react";

import { initSentry } from "@mobsuccess-devops/react-sentry";

import { Outlet, useMatch } from "../../../../packages/react-router/src";
import ActionsPopup from "../components/ActionsPopup";
import AppMarketingThemeProvider from "../components/AppMarketingThemeProvider";
import EnsureFromSearchParam from "../components/HOC/EnsureFromSearchParam";
import Portal from "../components/HOC/Portal";
import Header from "../components/Header";
import NavigationBar from "../components/NavigationBar";

function getSentryDsnFromEnv() {
  const dsnByUrlMatch = JSON.parse(
    import.meta.env.VITE_SENTRY_DSN_BY_URL_MATCH ?? "[]"
  );
  const { dsn } =
    dsnByUrlMatch.find(({ match }: { match: string }) =>
      window.location.href.match(new RegExp(match))
    ) ?? {};
  return dsn;
}

const dsn =
  window._msSentryDsn ||
  getSentryDsnFromEnv() ||
  "https://45edfe26c1f04ce68bf68911305f2ca3@o1028474.ingest.sentry.io/6501273";

initSentry({
  dsn,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? "local",
});

export const keepParams = [
  "pubid",
  "timestamp",
  "hash",
  "userid",
  "device",
  "devicemodel",
  "os_version",
  "is_tablet",
  "country",
  "ip",
  "connection_type",
  "locale",
  "pub0",
  "pub1",
  "idfa",
  "gaid",
  "idfasha1",
  "gaidsha1",
  "zip",
  "user_age",
  "user_gender",
  "user_signup_timestamp",
  "from",
];

const titles = {
  offers: "Home",
  "my-offers": "My offers",
  categories: "Categories",
  more: "More",
};

function isExistingPage(page?: string): page is keyof typeof titles {
  return Object.keys(titles).includes(page ?? "");
}

export default function App(): JSX.Element {
  const match = useMatch("/:page/*");
  const current = match?.params?.page;

  if (!isExistingPage(current)) {
    return <div>404</div>;
  }

  return (
    <AppMarketingThemeProvider>
      <EnsureFromSearchParam>
        <Header text={titles[current]} />
        <Outlet />
        <NavigationBar page={current} />
        <Portal>
          <ActionsPopup />
        </Portal>
      </EnsureFromSearchParam>
    </AppMarketingThemeProvider>
  );
}
