import { useCallback, useMemo } from "react";

import {
  SearchParams,
  ParamTypeMap,
  SearchParamOptions,
} from "../class/search-params";

import useSearchParams from "./useSearchParams";

type SearchParam<T extends keyof ParamTypeMap> = [
  searchParam: ParamTypeMap[T],
  setSearchParam: (
    searchParam: ParamTypeMap[T],
    options?: {
      replace?: boolean;
      state?: unknown;
    }
  ) => void
];

function useSearchParam<
  T extends keyof ParamTypeMap = keyof Pick<ParamTypeMap, "string">
>(
  param: string,
  { defaultValue, type }: SearchParamOptions<T> = {}
): SearchParam<T> {
  const [searchParams, setSearchParams] = useSearchParams();

  const options = useMemo<SearchParamOptions<T>>(
    () => ({
      defaultValue,
      type,
    }),
    [defaultValue, type]
  );

  const searchParam = useMemo(() => {
    const searchParam = searchParams.getTyped<T>(param, options);
    return searchParam;
  }, [searchParams, param, options]);

  const handleChangeSearchParam = useCallback<SearchParam<T>[1]>(
    (newValue, changeOptions) => {
      setSearchParams((prevSearchParams) => {
        const newSearchParams = new SearchParams(prevSearchParams);
        if (
          typeof newValue === "undefined" ||
          newValue === undefined ||
          newValue === options.defaultValue ||
          newValue === null ||
          newValue === ""
        ) {
          newSearchParams.deleteTyped(param, options);
          return newSearchParams;
        }
        newSearchParams.setTyped(param, newValue, options);
        return newSearchParams;
      }, changeOptions);
    },
    [options, param, setSearchParams]
  );

  return useMemo(() => {
    return [searchParam, handleChangeSearchParam];
  }, [searchParam, handleChangeSearchParam]);
}

export default useSearchParam;
