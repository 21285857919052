import { StrictMode } from "react";

import AppRouter from "@mobsuccess-devops/vite-plugins/router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { createRoot } from "react-dom/client";

import DataContextProvider from "./components/DataContextProvider";
import "./index.css";
import { unregister } from "./serviceWorker";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

const reactRoot = document.getElementById("root");

if (!reactRoot) {
  throw new Error("Could not find root element");
}
const root = createRoot(reactRoot);
const defaultData = {};

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <DataContextProvider data={defaultData}>
        <AppRouter />
      </DataContextProvider>
    </QueryClientProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
