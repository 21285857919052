import { JSX, memo } from "react";

import styled from "styled-components";

import { useIsFetchingOffersAtom } from "../../../features/atoms/offers";
import Spinner from "../../Spinner";

const PageLayoutUI = styled.div`
  padding: 32px 0;
  margin-bottom: calc(env(safe-area-inset-bottom) + 62px);
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

type PageLayoutProps = {
  children: JSX.Element | (JSX.Element | null)[];
};

function PageLayout({ children }: PageLayoutProps): JSX.Element {
  const [isFetching] = useIsFetchingOffersAtom();

  if (isFetching) {
    return <Spinner />;
  }

  return <PageLayoutUI>{children}</PageLayoutUI>;
}

const MemoizedPageLayout = memo(PageLayout);
export { MemoizedPageLayout as PageLayout };
