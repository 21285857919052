import { JSX, memo } from "react";

import styled from "styled-components";

import { Offer } from "../../types/offers";
import TextLG from "../UI/TextLG";
import TextSM from "../UI/TextSM";

import { SmallOffer } from "./SmallOffer/SmallOffer";

const TextLGUI = styled(TextLG)`
  padding: 0 24px;
`;

const SmallOfferListUI = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ListUI = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 24px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TextSMUI = styled(TextSM)`
  color: ${({ theme }) => theme.palette.neutral[500]};
  padding: 0 24px;
`;

type SmallOfferListProps = {
  title: string;
  offers: Offer[];
};

function SmallOfferList({ title, offers }: SmallOfferListProps): JSX.Element {
  return (
    <SmallOfferListUI>
      <TextLGUI>{title}</TextLGUI>
      {offers.length ? (
        <ListUI>
          {offers.map((offer) => (
            <SmallOffer offer={offer} key={offer.id} />
          ))}
        </ListUI>
      ) : (
        <TextSMUI>No offers yet.</TextSMUI>
      )}
    </SmallOfferListUI>
  );
}

const MemoizedSmallOfferList = memo(SmallOfferList);
export { MemoizedSmallOfferList as SmallOfferList };
