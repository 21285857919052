import {
  JSX,
  MouseEventHandler,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";

import { ReactComponent as DicesIcon } from "@mobsuccess-devops/streamline/custom/offerwall/dices.svg";
import { ReactComponent as FireIcon } from "@mobsuccess-devops/streamline/custom/offerwall/fire.svg";
import { ReactComponent as GameControllerIcon } from "@mobsuccess-devops/streamline/custom/offerwall/game-controller.svg";
import { ReactComponent as GiftIcon } from "@mobsuccess-devops/streamline/custom/offerwall/gift.svg";
import { ReactComponent as HeartIcon } from "@mobsuccess-devops/streamline/custom/offerwall/heart.svg";
import { ReactComponent as RegisterIcon } from "@mobsuccess-devops/streamline/custom/offerwall/register.svg";
import { ReactComponent as ShoppingBagIcon } from "@mobsuccess-devops/streamline/custom/offerwall/shopping-bag.svg";

import styled, { css } from "styled-components";

import { LargeOfferList } from "../components/LargeOfferList/LargeOfferList";
import { PageLayout } from "../components/UI/PageLayout/PageLayout";
import TextSM from "../components/UI/TextSM";
import { useFetchOffers } from "../features/actions/offers";
import { useOffersAtom } from "../features/atoms/offers";
import { Offer } from "../types/offers";

const ButtonListUI = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 24px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonUI = styled.button<{ $active: boolean }>`
  all: unset;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
  background-color: ${({ theme, $active }) =>
    $active ? theme.palette.secondary[500] : theme.palette.neutral[100]};
  ${({ $active }) =>
    $active &&
    css`
      * {
        color: white;
      }
    `};

  svg {
    height: 14px;
    width: 14px;
  }
`;

type Tab = {
  name: string;
  emoji: string;
  icon: JSX.Element;
  filter: (offer: Offer) => boolean;
};

function Home(): JSX.Element {
  useFetchOffers();
  const [offers] = useOffersAtom();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const tabs = useMemo<Tab[]>(
    () => [
      {
        name: "All Offers",
        emoji: "🎉",
        icon: <FireIcon />,
        filter: () => true,
      },
      {
        name: "Casino",
        emoji: "🎲",
        icon: <DicesIcon />,
        filter: ({ hasCasinoTag }) => hasCasinoTag,
      },
      {
        name: "Game",
        emoji: "🎮",
        icon: <GameControllerIcon />,
        filter: ({ hasGamesTag }) => hasGamesTag,
      },
      {
        name: "Purchase",
        emoji: "🛍️",
        icon: <ShoppingBagIcon />,
        filter: ({ hasPurchaseTag }) => hasPurchaseTag,
      },
      {
        name: "Registration",
        emoji: "📝",
        icon: <RegisterIcon />,
        filter: ({ hasRegistrationTag }) => hasRegistrationTag,
      },
      {
        name: "Free",
        emoji: "🎁",
        icon: <GiftIcon />,
        filter: ({ hasFreeTag }) => hasFreeTag,
      },
      {
        name: "Discovery",
        emoji: "💜",
        icon: <HeartIcon />,
        filter: ({ hasDiscoveryTag }) => hasDiscoveryTag,
      },
    ],
    []
  );

  const selectedTab = useMemo(
    () => tabs[selectedTabIndex],
    [selectedTabIndex, tabs]
  );

  const preFilteredOffers = useMemo(
    () =>
      offers.filter(
        ({ status }) => status !== "completed" && status !== "in_progress"
      ),
    [offers]
  );

  const filteredOffers = useMemo(
    () => preFilteredOffers.filter(selectedTab.filter),
    [preFilteredOffers, selectedTab.filter]
  );

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const index = Number(event.currentTarget.id.split("-")[1]);
      setSelectedTabIndex(index);
    },
    []
  );

  return (
    <PageLayout>
      <ButtonListUI>
        {tabs.map((tab, index) => (
          <ButtonUI
            key={index}
            id={`button-${index}`}
            onClick={handleClick}
            $active={index === selectedTabIndex}
          >
            {tab.icon}
            <TextSM>{tab.name}</TextSM>
          </ButtonUI>
        ))}
      </ButtonListUI>
      <LargeOfferList
        title={`${selectedTab.name} ${selectedTab.emoji}`}
        offers={filteredOffers}
      />
    </PageLayout>
  );
}

export default memo(Home);
