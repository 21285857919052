import { JSX } from "react";

import styled from "styled-components";

import { TextProps } from "../../../types/text";

type TextXLProps = TextProps;

const TextXLUI = styled.p`
  color: ${({ theme }) => theme.palette.neutral[900]};
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`;

TextXLUI.displayName = "TextXLUI";

function TextXL({ className, children }: TextXLProps): JSX.Element {
  return <TextXLUI className={className}>{children}</TextXLUI>;
}

export default TextXL;
