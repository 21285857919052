import { useCallback } from "react";

import { NavigateOptions, useLocation } from "react-router-dom";

import { OverloadTo } from "../../types/OverloadTo";
import { RelativeUrl } from "../class/relative-url";
import { useListeningRef } from "../references";
import { useRouterContext, useRouterStateContext } from "../router";

type RenderUrlWithKeepParams = (
  to: OverloadTo,
  options?: NavigateOptions
) => RelativeUrl;

function useRenderUrlWithKeepParams(): RenderUrlWithKeepParams {
  const { keep } = useRouterContext();
  const location = useLocation();
  const locationRef = useListeningRef(location);
  const { matches } = useRouterStateContext();
  const matchesRef = useListeningRef(matches);

  return useCallback<RenderUrlWithKeepParams>(
    (to, options) => {
      return new RelativeUrl({
        to,
        from: locationRef.current,
        keep,
        options,
        matches: matchesRef.current,
      });
    },
    [keep, locationRef, matchesRef]
  );
}

export default useRenderUrlWithKeepParams;
