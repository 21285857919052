import { JSX } from "react";

import styled from "styled-components";

import { Rows } from "../components/More";
import TextLG from "../components/UI/TextLG";

const MoreUI = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 24px;
`;

MoreUI.displayName = "MoreUI";

const WidgetUI = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  margin-top: 18px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadows.xs};
`;

WidgetUI.displayName = "WidgetUI";

export default function More(): JSX.Element {
  return (
    <MoreUI>
      <TextLG>See More 👀</TextLG>
      <WidgetUI>
        <Rows />
      </WidgetUI>
    </MoreUI>
  );
}
