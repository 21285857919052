import { useCallback, useState } from "react";

export function useRerender(): () => void {
  // this is a hack to force a rerender
  // eslint-disable-next-line react/hook-use-state
  const [, setRevision] = useState({});

  return useCallback(() => {
    setRevision({});
  }, []);
}
