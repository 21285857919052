import { JSX, memo } from "react";

import styled from "styled-components";

import { Offer } from "../../types/offers";
import TextLG from "../UI/TextLG";
import TextSM from "../UI/TextSM";

import { MediumOffer } from "./MediumOffer/MediumOffer";

const TextLGUI = styled(TextLG)`
  padding: 0 24px;
`;

const MediumOfferListUI = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ListUI = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px 24px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TextSMUI = styled(TextSM)`
  color: ${({ theme }) => theme.palette.neutral[500]};
  padding: 0 24px;
`;

type MediumOfferListProps = {
  title: string;
  offers: Offer[];
};

function MediumOfferList({ title, offers }: MediumOfferListProps): JSX.Element {
  return (
    <MediumOfferListUI>
      <TextLGUI>{title}</TextLGUI>
      {offers.length ? (
        <ListUI>
          {offers.map((offer) => (
            <MediumOffer offer={offer} key={offer.id} />
          ))}
        </ListUI>
      ) : (
        <TextSMUI>No offers yet.</TextSMUI>
      )}
    </MediumOfferListUI>
  );
}

const MemoizedMediumOfferList = memo(MediumOfferList);
export { MemoizedMediumOfferList as MediumOfferList };
