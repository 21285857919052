import { useCallback, useId, useEffect } from "react";

import {
  Atom,
  AtomSetter,
  AtomSetterAction,
  InitialAtomState,
} from "../../types/atom";
import { useAtomStore } from "../atom-store";
import { useRerender } from "../helpers/useRerender";

export default function useAtom<T>(
  atomId: string,
  initialState?: InitialAtomState<T>,
  {
    suspense,
  }: {
    suspense?: T;
  } = {}
): Atom<T> {
  const store = useAtomStore();
  const rerender = useRerender();
  const instance = useId();
  store.registerQueue(atomId, instance);

  const handleAtomChange = useCallback<AtomSetter<T>>(
    (action: AtomSetterAction<T>) => {
      const prev = store.grab<T>(atomId);
      const newValue = action instanceof Function ? action(prev) : action;
      store.update(atomId, newValue);
    },
    [store, atomId]
  );

  useEffect(() => {
    store.registerQueue(atomId, instance);
    store.onUpdate(atomId, rerender, instance);
    return () => {
      store.offUpdate(atomId, rerender, instance);
    };
  }, [store, atomId, rerender, instance]);

  return [
    store.ensureAtom<T>(atomId, initialState, suspense),
    handleAtomChange,
  ];
}
