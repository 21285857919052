import { JSX } from "react";

import styled from "styled-components";

import { TextProps } from "../../../types/text";

type TextXXLProps = TextProps;

const TextXXLUI = styled.p`
  color: ${({ theme }) => theme.palette.neutral[900]};
  font-size: 28px;
  font-weight: 800;
  line-height: 39px;
`;

TextXXLUI.displayName = "TextXXLUI";

function TextXXL({ className, children }: TextXXLProps): JSX.Element {
  return <TextXXLUI className={className}>{children}</TextXXLUI>;
}

export default TextXXL;
