import { createContext, Context } from "react";

import { EventEmitter } from "events";

import { InitialAtomState } from "../../types/atom";

import { UpdateQueue } from "./queue";

export class AtomStore extends EventEmitter {
  private static instance = createContext<AtomStore | null>(null);
  static get context(): Context<AtomStore | null> {
    return AtomStore.instance;
  }
  static get Provider(): Context<AtomStore | null>["Provider"] {
    return AtomStore.instance.Provider;
  }
  static get Consumer(): Context<AtomStore | null>["Consumer"] {
    return AtomStore.instance.Consumer;
  }

  private atoms: Record<string, unknown> = {};
  private queue: UpdateQueue<string> = new UpdateQueue();

  constructor(initialStore: Record<string, unknown> = {}) {
    super();
    super.setMaxListeners(Infinity);
    for (const item in initialStore) {
      this.atoms[`atom:${item}`] = initialStore[item];
    }
  }

  ensureAtom<T>(
    id: string,
    initialValue?: InitialAtomState<T>,
    suspense?: T
  ): T {
    const atomId = `atom:${id}`;
    if (atomId in this.atoms) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return this.atoms[atomId] as T;
    }
    if (initialValue instanceof Function) {
      const value = initialValue();
      if (value instanceof Promise) {
        this.atoms[atomId] = suspense;
        (async () => {
          const v = await value;
          this.update(id, v);
        })();
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return this.atoms[atomId] as T;
      }
      this.atoms[atomId] = value;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return this.atoms[atomId] as T;
    }
    this.atoms[atomId] = initialValue;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return this.atoms[atomId] as T;
  }

  grab<T>(id: string): T {
    const atomId = `atom:${id}`;
    if (atomId in this.atoms) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return this.atoms[atomId] as T;
    }
    throw new Error(
      `[@mobsuccess-devops/react-atom] AtomStore: Atom "${id}" not found!\n` +
        "You should not clear atoms that are still beeing used."
    );
  }

  public update<T>(id: string, newValue: T): void {
    const atomId = `atom:${id}`;
    this.atoms[atomId] = newValue;
    super.emit(atomId, this.atoms[atomId]);
    this.queue.dispatch(atomId, "queued", (state) => state === "empty");
  }

  public registerQueue(id: string, instance: string): void {
    const atomId = `atom:${id}`;
    const queue = this.queue.ensureQueue(atomId);
    if (!queue.has(instance)) {
      queue.set(instance, "empty");
    }
  }

  public onUpdate(id: string, callback: () => void, instance: string): void {
    const atomId = `atom:${id}`;
    super.on(atomId, callback);
    const prevState = this.queue.update(atomId, instance, "listening");
    if (prevState === "queued") {
      callback();
    }
  }

  public offUpdate<T>(
    id: string,
    callback: (newValue: T) => void,
    instance: string
  ): void {
    const atomId = `atom:${id}`;
    super.off(atomId, callback);
    this.queue.removeInstance(atomId, instance);
  }
}
