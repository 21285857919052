import { PropsWithChildren, memo, JSX, useMemo } from "react";

import {
  ContextData,
  DataContext,
} from "@mobsuccess-devops/react-data-context";

import { getServerHostForEntity } from "../../features/api/config";
import { getConfig } from "../../features/query/query";

type DataContextProviderBaseProps = PropsWithChildren<{
  data: ContextData;
}>;

function DataContextProviderBase({
  children,
  data,
}: DataContextProviderBaseProps): JSX.Element | null {
  const dataContextConfig = useMemo(() => {
    const { queryBranch, dataEnvironment } = getConfig();
    return {
      queryBranch,
      dataEnvironment,
      host: getServerHostForEntity({ entity: "query" }),
      services: {
        offerwall: import.meta.env.VITE_DATA_CONTEXT_SERVICE_OFFERWALL,
      },
    };
  }, []);

  if (!dataContextConfig) {
    return null;
  }

  return (
    <DataContext data={data} config={dataContextConfig}>
      {children}
    </DataContext>
  );
}

export default memo(DataContextProviderBase);
