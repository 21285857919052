import { ReactNode, memo, JSX } from "react";

import Row from "./Row";

type RowBaseProps = {
  onClick?: () => void;
  icon: ReactNode;
  text: string;
};

const defaultOnClick = (): null => null;
function RowBase({
  icon,
  text,
  onClick = defaultOnClick,
}: RowBaseProps): JSX.Element {
  return <Row onClick={onClick} icon={icon} text={text} />;
}

export default memo(RowBase);
