import { JSX } from "react";

import styled from "styled-components";

import { TextProps } from "../../../types/text";

type TextMDProps = TextProps;

const TextMDUI = styled.p`
  color: ${({ theme }) => theme.palette.neutral[900]};
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
`;

TextMDUI.displayName = "TextMDUI";

function TextMD({ className, children, onClick }: TextMDProps): JSX.Element {
  return (
    <TextMDUI className={className} onClick={onClick}>
      {children}
    </TextMDUI>
  );
}

export default TextMD;
