export type ParamTypeMap = {
  string?: string | null;
  number?: number | null;
  boolean?: boolean | null;
  array?: string[] | null;
  object?: Record<string, string> | null;
};

export type SearchParamOptions<T extends keyof ParamTypeMap> = {
  type?: T;
  defaultValue?: ParamTypeMap[T];
};

export class SearchParams extends URLSearchParams {
  constructor(
    init: string[][] | Record<string, string> | string | URLSearchParams
  ) {
    super(init);
  }

  private getObject(
    name: string,
    defaultValue: Record<string, string>
  ): ParamTypeMap["object"] {
    const array = Array.from(super.entries()).filter(([key]) =>
      key.startsWith(name)
    );

    const object = array.reduce(
      (acc: Record<string, string> | undefined, [key, value]) => {
        const keyName = key.replace(name, "");
        return {
          ...(acc ?? {}),
          [keyName]: value,
        };
      },
      undefined
    );
    return object ?? defaultValue;
  }

  getTyped<T extends keyof ParamTypeMap>(
    name: string,
    { type, defaultValue }: SearchParamOptions<T>
  ): ParamTypeMap[T] {
    switch (type) {
      case "object":
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return this.getObject(
          name,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          defaultValue as Record<string, string>
        ) as ParamTypeMap[T];
      case "array": {
        const value = super.get(name);
        if (value) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          return value.split(",") as ParamTypeMap[T];
        }
        return typeof value === "string"
          ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            ([] as unknown as ParamTypeMap[T])
          : defaultValue;
      }
      case "number": {
        const value = super.get(name);
        if (value) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          return Number(value) as ParamTypeMap[T];
        }
        return defaultValue;
      }
      case "boolean": {
        const value = super.get(name);
        if (value) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          return !["false", "0", "off"].includes(
            value.toLowerCase()
          ) as ParamTypeMap[T];
        }
        return defaultValue;
      }
      default:
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return (super.get(name) as ParamTypeMap[T]) ?? defaultValue;
    }
  }

  deleteTyped<T extends keyof ParamTypeMap>(
    name: string,
    { type }: SearchParamOptions<T>
  ): void {
    if (type === "object") {
      const keys = Array.from(super.keys());
      keys.forEach((key) => {
        if (key.startsWith(name)) {
          super.delete(key);
        }
      });
    } else {
      super.delete(name);
    }
  }

  setTyped<T extends keyof ParamTypeMap>(
    name: string,
    value: NonNullable<ParamTypeMap[T]>,
    options: SearchParamOptions<T>
  ): void {
    switch (options.type) {
      case "object": {
        this.deleteTyped(name, { type: options.type });
        Object.entries(value).forEach(([key, keyValue]) => {
          super.set(`${name}${key}`, keyValue);
        });
        break;
      }
      case "array": {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        super.set(name, (value as string[]).join(","));
        break;
      }
      case "number": {
        super.set(name, String(value));
        break;
      }
      case "boolean": {
        super.set(name, String(value));
        break;
      }
      default:
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        super.set(name, value as string);
    }
  }

  toString(): string {
    const string = super.toString();
    return string ? `?${string}` : "";
  }
}
