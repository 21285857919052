import { memo, JSX, useCallback } from "react";

import { Link } from "@mobsuccess-devops/react-router";

import styled from "styled-components";

import { ReactComponent as BicycleSVG } from "../../assets/icons/custom/bicycle.svg";
import tourImage from "../../assets/tour-image.png";
import { useTourIntroModalAtom } from "../../features/atoms/global";
import TextMD from "../UI/TextMD";
import TextXL from "../UI/TextXL";

const TourIntroModalUI = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackdropUI = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: auto;
  transition: background-color 0.5s ease-in-out;
`;

const ModalUI = styled.div<{ $display: boolean }>`
  position: absolute;
  z-index: 200;
  display: flex;
  width: calc(100% - 48px);
  max-width: 350px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-top: 44px;
  background-color: #fff;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  pointer-events: auto;
  gap: 16px;
`;

const TourDescription = styled(TextMD)`
  color: ${({ theme }) => theme.palette.neutral[600]};
  text-align: center;
`;

const StartButtonUI = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.palette.secondary[500]};
  border-radius: 6px;
`;

const ButtonText = styled(TextXL)`
  color: ${({ theme }) => theme.colors.white};
`;

const LinkUI = styled(Link)`
  color: ${({ theme }) => theme.palette.secondary[500]};
`;

const TourImageUI = styled.img`
  position: absolute;
  z-index: 200;
  bottom: 70px;
  width: 85vw;
`;

function TourIntroModal(): JSX.Element | null {
  const [showModal, setShowModal] = useTourIntroModalAtom();

  const handleSkip = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return showModal ? (
    <TourIntroModalUI>
      <ModalUI $display>
        <BicycleSVG />
        <TextXL>Let&apos;s get started</TextXL>
        <TourDescription>
          You haven&apos;t selected any offers yet!
          <br />
          Go to the{" "}
          <LinkUI to="/offers" onClick={handleSkip}>
            Home
          </LinkUI>{" "}
          or{" "}
          <LinkUI to="/categories" onClick={handleSkip}>
            Categories
          </LinkUI>{" "}
          pages to get started with an offer!
        </TourDescription>
        <StartButtonUI onClick={handleSkip}>
          <ButtonText>I understand</ButtonText>
        </StartButtonUI>
      </ModalUI>
      <TourImageUI src={tourImage} alt="" />
      <BackdropUI onClick={handleSkip} />
    </TourIntroModalUI>
  ) : null;
}

const MemoizedTourIntroModal = memo(TourIntroModal);
export { MemoizedTourIntroModal as TourIntroModal };
