import { ReactNode, memo, JSX } from "react";

import styled from "styled-components";

import TextXS from "../UI/TextXS";

type BadgeUIProps = {
  $color?: string;
};

const BadgeUI = styled.div<BadgeUIProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  background-color: ${({ $color }) => $color};
  border-radius: 6px;
`;

BadgeUI.displayName = "BadgeUI";

const OfferNameUI = styled(TextXS)`
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
`;

type BadgeProps = {
  color?: string;
  children?: ReactNode;
};

function Badge({ children, color }: BadgeProps): JSX.Element {
  return (
    <BadgeUI $color={color}>
      <OfferNameUI>{children}</OfferNameUI>
    </BadgeUI>
  );
}

export default memo(Badge);
