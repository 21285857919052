import { JSX } from "react";

import styled from "styled-components";

import { TextProps } from "../../../types/text";

type TextLGProps = TextProps;

const TextLGUI = styled.p`
  color: ${({ theme }) => theme.palette.neutral[900]};
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
`;

TextLGUI.displayName = "TextLGUI";

function TextLG({ className, children }: TextLGProps): JSX.Element {
  return <TextLGUI className={className}>{children}</TextLGUI>;
}

export default TextLG;
