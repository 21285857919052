import { ReactNode, memo, JSX } from "react";

import styled from "styled-components";

import { ReactComponent as ArrowIcon } from "../../../assets/icons/custom/arrow-right.svg";
import TextMD from "../../UI/TextMD";

const RowUI = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  &:first-child {
    border-radius: 10px 10px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 0px 10px 10px;

    div {
      border: none;
    }
  }
`;

RowUI.displayName = "RowUI";

const SVGUI = styled.div`
  display: flex;
  width: 48px;
  align-items: center;
  justify-content: center;
  margin-top: -2px;

  svg {
    width: 20px;
    stroke: ${({ theme }) => theme.palette.secondary[500]};

    .fl0 {
      fill: ${({ theme }) => theme.palette.secondary[500]};
      stroke: none;
    }
  }
`;

SVGUI.displayName = "SVGUI";

const ContainerUI = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral[300]}`};
`;

ContainerUI.displayName = "ContainerUI";

const ArrowUI = styled(ArrowIcon)`
  width: 8px;
  margin-right: 15px;
  stroke: ${({ theme }) => theme.palette.neutral[300]};
`;

ArrowUI.displayName = "ArrowUI";

type RowProps = {
  icon: ReactNode;
  text: string;
  onClick: () => void;
};

function Row({ icon, text, onClick }: RowProps): JSX.Element {
  return (
    <RowUI onClick={onClick}>
      <SVGUI>{icon}</SVGUI>
      <ContainerUI>
        <TextMD>{text}</TextMD>
        <ArrowUI />
      </ContainerUI>
    </RowUI>
  );
}

export default memo(Row);
