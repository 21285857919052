import { memo, useMemo, JSX } from "react";

import { ReactComponent as CategoriesIcon } from "@mobsuccess-devops/streamline/custom/offerwall/categories.svg";
import { ReactComponent as LightningIcon } from "@mobsuccess-devops/streamline/custom/offerwall/lightning.svg";

import { useSearchParams } from "../../../../../packages/react-router/src";
import { ReactComponent as HomeIcon } from "../../assets/icons/curve/home.svg";
import { ReactComponent as MoreIcon } from "../../assets/icons/curve/more-square.svg";

import NavigationBar from "./NavigationBar";

type NavigationBarBaseProps = {
  page?: string;
};

function NavigationBarBase({ page }: NavigationBarBaseProps): JSX.Element {
  const [searchParams] = useSearchParams();

  const items = useMemo(() => {
    const hasFallback = searchParams.has("fallback");
    return [
      ...(hasFallback
        ? []
        : [
            {
              url: "offers",
              text: "Home",
              icon: <HomeIcon />,
            },
            {
              url: "my-offers",
              text: "My offers",
              icon: <LightningIcon />,
            },
          ]),
      {
        url: "categories",
        text: "Categories",
        icon: <CategoriesIcon />,
      },
      {
        url: "more",
        text: "More",
        icon: <MoreIcon />,
      },
    ];
  }, [searchParams]);

  return <NavigationBar page={page} items={items} />;
}

export default memo(NavigationBarBase);
