import { memo, useCallback, useRef, JSX } from "react";

import QRCode from "react-qr-code";
import styled from "styled-components";

import TextXL from "../UI/TextXL";

const DesktopLinkUI = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  gap: 20px;
`;

DesktopLinkUI.displayName = "DesktopLinkUI";

const EmailFormUI = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const TitleUI = styled(TextXL)`
  color: ${({ theme }) => theme.palette.neutral[900]};
  line-height: 1;
`;

TitleUI.displayName = "TitleUI";

const InputUI = styled.input`
  padding: 10px;
  border: 1px solid #9da7a9;
  border-radius: 5px;
  outline: none;
  ::placeholder {
    color: ${({ theme }) => theme.palette.neutral[500]};
  }
`;

InputUI.displayName = "InputUI";

const SubmitUI = styled.button`
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  border: none;
  background: ${({ theme }) => theme.palette.secondary[500]};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
`;

SubmitUI.displayName = "SubmitUI";

EmailFormUI.displayName = "EmailFormUI";

const ColumnsUI = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

ColumnsUI.displayName = "ColumnsUI";

const OptionUI = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

OptionUI.displayName = "OptionUI";

const HorizontalSeparatorUI = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  background: #9da7a9;

  :after {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 10px;
    background: white;
    color: #9da7a9;
    content: "or";
    font-size: 14px;
    transform: translate(-50%, -50%);
  }
`;

HorizontalSeparatorUI.displayName = "HorizontalSeparatorUI";

const VerticalSeparatorUI = styled.div`
  flex: 0 0 1px;
  align-self: stretch;
  background: #9da7a9;
`;

VerticalSeparatorUI.displayName = "VerticalSeparatorUI";

const LinkUI = styled.div`
  overflow: hidden;
  width: 180px;
  padding: 5px 10px;
  border: none;
  background: #f1f2f8;
  border-radius: 5px;
  color: #9da7a9;
  cursor: pointer;
  outline: none;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

LinkUI.displayName = "LinkUI";

export type DesktopLinkProps = {
  link: string;
};

function DesktopLink({ link }: DesktopLinkProps): JSX.Element {
  const linkRef = useRef<HTMLDivElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);

  const handleCopyToClipboard = useCallback(() => {
    if (linkRef?.current === null) {
      return;
    }

    navigator.clipboard.writeText(link);
    linkRef.current.textContent = "Copied !";

    setTimeout(() => {
      if (linkRef?.current === null) {
        return;
      }

      linkRef.current.textContent = link;
    }, 1000);
  }, [link, linkRef]);

  const handleSendEmail = useCallback(() => {
    if (emailInputRef?.current === null) {
      return;
    }
    const subject = encodeURIComponent("Your Farly Offer Link");
    const body = encodeURIComponent(
      `Hey! Your link to complete the offer is below!\n` +
        `The offer’s requirements are available on the wall.\n\n` +
        `${link}\n\n` +
        `Thanks,\n` +
        `Farly team!\n\n`
    );
    const email = emailInputRef.current.value;
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  }, [link]);

  return (
    <DesktopLinkUI>
      <EmailFormUI>
        <TitleUI>Send this offer to your Email!</TitleUI>
        <InputUI ref={emailInputRef} type="email" placeholder="Email address" />
        <SubmitUI onClick={handleSendEmail}>Send Link</SubmitUI>
      </EmailFormUI>
      <HorizontalSeparatorUI />
      <ColumnsUI>
        <OptionUI>
          <QRCode value={link} size={124} />
        </OptionUI>
        <VerticalSeparatorUI />
        <OptionUI>
          <LinkUI ref={linkRef} title={link} onClick={handleCopyToClipboard}>
            {link}
          </LinkUI>
        </OptionUI>
      </ColumnsUI>
    </DesktopLinkUI>
  );
}

export default memo(DesktopLink);
