import { useCallback } from "react";

import { Atom } from "../../types/atom";
import { useAtomStore } from "../atom-store";

import useSetAtom from "./useSetAtom";

export default function useGetAtom(): <T>(atomId: string) => Atom<T> {
  const store = useAtomStore();
  const setAtom = useSetAtom();
  return useCallback(
    <T>(atomId: string) => {
      const atom = store.grab<T>(atomId);
      return [atom, (action) => setAtom(atomId, action)];
    },
    [setAtom, store]
  );
}
