import { memo, JSX } from "react";

import styled, { keyframes } from "styled-components";

const SpinnerUI = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

SpinnerUI.displayName = "SpinnerUI";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerCircleUI = styled.div`
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-top: 10px solid ${({ theme }) => theme.palette.secondary[500]};
  animation: ${rotate} 1s linear infinite;
  border-radius: 50%;
`;

SpinnerCircleUI.displayName = "SpinnerCircleUI";

function Spinner(): JSX.Element {
  return (
    <SpinnerUI>
      <SpinnerCircleUI />
    </SpinnerUI>
  );
}

export default memo(Spinner);
