import { captureException } from "@sentry/react";

class SentryFetchError extends Error {
  constructor({ response }: { response: Response }) {
    const { status, url } = response;
    super(
      `Received HTTP code ${status} when fetching from ${new URL(url).hostname}`
    );
    this.name = "SentryFetchError";
  }
}

export const nativeFetch = window.fetch;

async function fetchInterceptErrors(
  this: Window,
  ...args: Parameters<Window["fetch"]>
) {
  const response = await nativeFetch.apply(this, args);
  const { status } = response;
  if (status >= 400) {
    // log error to Sentry
    captureException(new SentryFetchError({ response }), {
      extra: { ...response },
      tags: {
        interceptor: "FetchInterceptor",
        httpStatusCode: status,
      },
    });
  }
  return response;
}

Object.assign(window, { fetch: fetchInterceptErrors });
