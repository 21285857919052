import { useCallback } from "react";

import { useAtom } from "@mobsuccess-devops/react-atom";

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import type { CallRestApiOptions, QueryValueType } from "../../types/context";
import { useDataContextContext } from "../context/hooks";
import { getRouteUri, parseQueryName } from "../route/route";
import { getQueryId } from "../utils/queryId";

export function useCallRestApi<RequestDataType, ResponseType>(): <
  RestApiRoute extends keyof DataContextRestApiArgs
>(
  queryName: RestApiRoute,
  queryArgs: DataContextRestApiArgs[RestApiRoute],
  { requestConfig }?: CallRestApiOptions<RequestDataType>
) => Promise<AxiosResponse<ResponseType>> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return useCallAnyRestApi() as <
    RestApiRoute extends keyof DataContextRestApiArgs
  >(
    queryName: RestApiRoute,
    queryArgs: DataContextRestApiArgs[RestApiRoute],
    { requestConfig }?: CallRestApiOptions<RequestDataType>
  ) => Promise<AxiosResponse<ResponseType>>;
}

/**
 *
 * @description It avoids simultaneous calls to the same url when using GET method
 *
 * @param config - Axios request config
 * @returns a promise that resolves to the response from the REST API
 * if the the method is GET, the promise is stored during the lifetime of the request
 * and any other requests for the same query will return the same promise
 *
 */
function useMakeUniqueAxiosRequest(): <TRequest, TResponse>(
  config: AxiosRequestConfig<TRequest>
) => Promise<AxiosResponse<TResponse>> {
  const [currentGetRequests] = useAtom<Record<string, Promise<unknown>>>(
    "#RDC.rest-api.rest-api.currentGetRequests",
    () => ({})
  );

  return useCallback(
    async <TRequest, TResponse>(
      config: AxiosRequestConfig<TRequest>
    ): Promise<AxiosResponse<TResponse>> => {
      // @ts-expect-error TODO fix this error
      if (config.method.toUpperCase() !== "GET") {
        return axios.request(config);
      }
      // @ts-expect-error TODO fix this error
      const queryId = getQueryId(config.url, config.params);
      if (queryId in currentGetRequests) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const promise = currentGetRequests[queryId] as Promise<
          AxiosResponse<TResponse>
        >;
        return promise;
      }
      try {
        const promise = axios.request(config);
        currentGetRequests[queryId] = promise;
        return await promise;
      } finally {
        delete currentGetRequests[queryId];
      }
    },
    [currentGetRequests]
  );
}

export function useCallAnyRestApi(): <
  ResponseType,
  RequestDataType = never,
  RestApiRoute extends keyof DataContextRestApiArgs | string = string
>(
  queryName: RestApiRoute,
  queryArgs: RestApiRoute extends keyof DataContextRestApiArgs
    ? DataContextRestApiArgs[RestApiRoute]
    : { [key: string]: QueryValueType },
  options?: CallRestApiOptions<RequestDataType>
) => Promise<AxiosResponse<ResponseType>> {
  const contextValue = useDataContextContext();
  const { config, authorizationRef } = contextValue;

  const makeUniqueAxiosRequest = useMakeUniqueAxiosRequest();
  return useCallback(
    async <
      ResponseType,
      RequestDataType = never,
      RestApiRoute extends keyof DataContextRestApiArgs | string = string
    >(
      queryName: RestApiRoute,
      queryArgs: RestApiRoute extends keyof DataContextRestApiArgs
        ? DataContextRestApiArgs[RestApiRoute]
        : { [key: string]: QueryValueType },
      options?: CallRestApiOptions<RequestDataType>
    ) => {
      const { requestConfig = {} } = options ?? {};
      const { method = "GET" } = parseQueryName(queryName);
      const url = getRouteUri({ config, queryName, queryArgs });
      const headers = {
        authorization: authorizationRef.current,
        ...(requestConfig.headers ?? {}),
      };

      return await makeUniqueAxiosRequest<RequestDataType, ResponseType>({
        ...requestConfig,
        method,
        headers,
        url,
        retry: true,
      });
    },
    [config, authorizationRef, makeUniqueAxiosRequest]
  );
}
